import { Navigate, useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ErrorRoutes from './ErrorRoutes';

import { ORDER_PATH } from 'config';
// import useAuth from 'hooks/useAuth';
// import MainLayout from 'layout/MainLayout';
// import AuthGuard from 'utils/route-guard/AuthGuard';
// import Loadable from '../ui-component/Loadable';
// import { lazy } from 'react';

// todo : keymedi2408 종료 후 삭제할 것
// const KeymediOrderInfo = Loadable(lazy(() => import('views/temp/KeymediOrderInfo')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // const { vendor } = useAuth();

  const routes = [{ path: '/', element: <Navigate to={ORDER_PATH} replace /> }, LoginRoutes, MainRoutes];

  // narapos 매장일 경우 pos route 추가
  // if (vendor?.nara_pos_flag) {
  //   routes.push({
  //     path: '/',
  //     element: (
  //       <AuthGuard>
  //         <MainLayout />
  //       </AuthGuard>
  //     ),
  //     children: [
  //       {
  //         // todo keymedi2408: 키메디 주문확인 페이지
  //         path: '/pos/keymedi2408/order-info',
  //         element: <KeymediOrderInfo />
  //       }
  //     ]
  //   });
  // }

  routes.push(ErrorRoutes);

  routes.push({
    path: '*',
    element: <Navigate to="/error/404" replace />
  });

  return useRoutes(routes);
}
